.it-footer .it-footer-main .it-brand-wrapper {
  .nextGenerationEULogo {
    height: 60px;
    width: auto;
    aspect-ratio: 167/41;
  }

  .logo-footer {
    margin-right: 1em;
    @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      height: 60px;
      width: auto;
    }
  }
}
