//site
$primary-h: 215 !default;
$primary-s: 39 !default;
$primary-b: 49 !default;

$primary: #4c75ae;
//$secondary;
//$tertiary: #fed505;
//$tertiary-text: $white;

//$header-bg-color: $monet-blue;
//$header-center-bg-color: $header-bg-color;

//$headings-color: $primary;
//$semibold: 600;
