.it-header-wrapper {
  .it-header-slim-wrapper {
    background-color: #636d88;
  }

  .it-header-center-wrapper.it-header-center-wrapper {
    background-color: white;
  }

  .it-nav-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper a,
      .it-right-zone,
      .it-right-zone .it-socials ul .icon {
        color: #3a3a3a;
        fill: #3a3a3a;
      }
      .it-search-wrapper a.rounded-icon {
        background-color: $primary;

        svg {
          fill: white;
        }

        &:focus,
        &:hover {
          background: shade-color(#3a3a3a, 10%);
        }
      }
    }
    .it-brand-wrapper {
      svg,
      img {
        width: auto;
        height: 82px;
        @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
          height: 60px;
          margin-left: 20px;
        }
      }

      figure {
        margin: 0 2em 0 0;
        @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
          margin: 0 1em 0 0;
        }
      }

      .it-brand-text {
        &,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: inherit;
        }

        h3 {
          text-transform: uppercase;
        }
      }
    }

    .it-header-navbar-wrapper {
      nav {
        background-color: $primary;

        padding-left: 8px;
        padding-right: 8px;

        .navbar-collapsable .menu-wrapper .it-brand-wrapper {
          svg,
          img {
            width: auto;
            height: 60px;
          }
        }
      }
    }
  }
}
